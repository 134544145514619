import Vue from 'vue';

/**
 * Define o status de carregamento.
 * @param {Object} state
 * @param {Boolean} status
 */
export function setLoadingStatus(state, status) {
  state.isLoading = status;
}

/**
 * Adiciona um novo item à listagem ou substitui um item existente.
 * @param {Object} state
 * @param {Object} item
 */
export function addItem(state, item) {
  const index = state.items.findIndex((i) => i.id === parseInt(item.id, 10));
  if (index >= 0) {
    // Este código não funciona:
    // state.items[index] = item;
    // O Vue não consegue detectar certas mudanças em arrays.
    // https://vuejs.org/v2/guide/list.html#Caveats
    Vue.set(state.items, index, item);
  } else {
    state.items.push(item);
  }
}

/**
 * Remove um item da listagem com base em sua ID.
 * @param {Object} state
 * @param {Number} id
 */
export function removeItem(state, id) {
  const index = state.items.findIndex((i) => i.id === parseInt(id, 10));
  if (index >= 0) state.items.splice(index, 1);
}

/**
 * Adiciona múltiplos itens à listagem, substituindo itens preexistentes.
 * @param {Object} state
 * @param {Array} items
 */
export function addCollection(state, items) {
  items.forEach((item) => addItem(state, item));
}

/**
 * Define múltiplos itens na listagem, substituindo itens preexistentes.
 * @param {Object} state
 * @param {Array} items
 */
export function setCollection(state, items) {
  state.items = items;
}

/**
 * Define a contagem de items.
 * @param {Object} state
 * @param {Integer} count
 */
export function setCount(state, count) {
  state.count = count;
}

/**
 * Define um item, substituindo o existente.
 * @param {Object} state
 * @param {Array} items
 */
export function setItem(state, item) {
  state.item = item;
}

/**
 * Define uma paginação, substituindo a existente.
 * @param {Object} state
 * @param {Number} page
 */
export function setPage(state, page) {
  state.page = page;
}

/**
 * Define um arquivo PDF em base64.
 * @param {Object} state
 * @param {string} documentBase64
 */
export function setDocument(state, documentBase64) {
  state.documentBase64 = documentBase64;
}

export function setDocPdf(state, documentBase64) {
  state.documentBase64 = documentBase64;
}
